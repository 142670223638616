import React, { useEffect, useState } from 'react';
import { Button } from '../../globalStyles';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from 'react-icons/fa';
import "./foot.css";
import {
  FooterContainer,
  FooterSubscription,
  FooterSubText,
  FooterSubHeading,
  Form,
  FormInput,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcon,
  WebsiteRights,
  SocialIcons,
  SocialIconLink
} from './Footer.elements';

function Footer() {
  const date = new Date();
  const [toast, setToast] = useState({error: "", success:""});
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (toast?.error || toast?.success) { 
      setTimeout(() => {
        setToast({error: "", success:""})
      }, 5000)
    }
  }, [toast])
 
  return (
    <FooterContainer>

        <div className={`toast ${!!toast?.error || !!toast?.success ? "open" : "close"}`} style={{
          width: 'calc(100% - 2em)',
          background: toast?.error ? 'rgba(255,90,90, 1)' : 'rgba(40,145,40, 1)',
          borderColor: toast?.error ? 'rgba(255,40,40, 1)' : 'rgba(20,145,20, 1)',
          color: toast?.error ? '#FFF' : '#FFF'
        }}>
          <span style={{margin: "auto", display: "flex"}}>
            {toast?.success || toast?.error}
          </span>
        </div>

      {/* { (toast?.success || toast?.error) && (
        
      )} */}

      <FooterSubscription>
        <FooterSubHeading>
          {"Soyez informé en avant première de la sortie de l'app"}
        </FooterSubHeading>
        {/* <FooterSubText>Vous.</FooterSubText> */}
        <Form onSubmit={async (evt) => {
            evt.preventDefault();
            try {
              const formData = new FormData();
              formData.append('EMAIL', email);
              
              await fetch(
                "https://5010f1e2.sibforms.com/serve/MUIFABl8QWa6_VxmQxb2CAl4bM-Pc44q3Urs8aOd149fmXeWATkW8ds05oeEG6-kTY_BfBIluG5pHZkcxXDkDM1kAjelHWll8QdJCuB5bLa7ZN6m542VqFor9V8juz2TrKiyMZvQ-Suzd9uo5vIuJtGy4lw4wnqoYk8QLlbzF-4wg2QAn8ALypZrcM_j20VgdNDU4KIP8BaBV9zz", 
                {
                method: "POST",
                body: formData
              })
              setToast({success: "Pour confirmer votre inscription sur la liste d'attente, cliquez sur le lien reçu par email"})
            } catch (e) {
              setToast({success: "Pour confirmer votre inscription sur la liste d'attente, cliquez sur le lien reçu par email"})
              // setToast({error: "Oups.. Une erreur s'est produite. Votre adresse email est elle bien valide ?"})
            }
          }} 
          method='POST' action='https://5010f1e2.sibforms.com/serve/MUIFABl8QWa6_VxmQxb2CAl4bM-Pc44q3Urs8aOd149fmXeWATkW8ds05oeEG6-kTY_BfBIluG5pHZkcxXDkDM1kAjelHWll8QdJCuB5bLa7ZN6m542VqFor9V8juz2TrKiyMZvQ-Suzd9uo5vIuJtGy4lw4wnqoYk8QLlbzF-4wg2QAn8ALypZrcM_j20VgdNDU4KIP8BaBV9zz'>
          <FormInput onChange={evt => setEmail(evt.target.value)} value={email} id="EMAIL" name="EMAIL" type='email' placeholder='Email' />
          <Button fontBig>{"Valider"}</Button>
        </Form>
      </FooterSubscription>
      {/* <FooterLinksContainer>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>About Us</FooterLinkTitle>
            <FooterLink to='/sign-up'>How it works</FooterLink>
            <FooterLink to='/'>Testimonials</FooterLink>
            <FooterLink to='/'>Careers</FooterLink>
            <FooterLink to='/'>Investors</FooterLink>
            <FooterLink to='/'>Terms of Service</FooterLink>
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkTitle>Contact Us</FooterLinkTitle>
            <FooterLink to='/'>Contact</FooterLink>
            <FooterLink to='/'>Support</FooterLink>
            <FooterLink to='/'>Destinations</FooterLink>
            <FooterLink to='/'>Sponsorships</FooterLink>
          </FooterLinkItems>
        </FooterLinksWrapper>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>Videos</FooterLinkTitle>
            <FooterLink to='/'>Submit Video</FooterLink>
            <FooterLink to='/'>Ambassadors</FooterLink>
            <FooterLink to='/'>Agency</FooterLink>
            <FooterLink to='/'>Influencer</FooterLink>
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkTitle>Social Media</FooterLinkTitle>
            <FooterLink to='/'>Instagram</FooterLink>
            <FooterLink to='/'>Facebook</FooterLink>
            <FooterLink to='/'>Youtube</FooterLink>
            <FooterLink to='/'>Twitter</FooterLink>
          </FooterLinkItems>
        </FooterLinksWrapper>
      </FooterLinksContainer> */}
      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to='/'>
            <SocialIcon />
            <span style={{fontSize: 16}}>
              {"L'art ne se consomme pas,"}
              <br />
              {"il se collectionne"}
            </span>
          </SocialLogo>
          <WebsiteRights>N4 © {date.getFullYear()} </WebsiteRights>
          <SocialIcons>
            <SocialIconLink href='https://www.instagram.com/num4.art/' target='_blank' aria-label='Instagram'>
              <FaInstagram />
            </SocialIconLink>

            <SocialIconLink href='https://www.linkedin.com/in/baptiste-launay-983a21194/' target='_blank' aria-label='LinkedIn'>
              <FaLinkedin />
            </SocialIconLink>
            <SocialIconLink href='https://twitter.com/num4_art' target='_blank' aria-label='Twitter'>
              <FaTwitter />
            </SocialIconLink>

            {/* <SocialIconLink href='/' target='_blank' aria-label='Tiktok'>
              <FaFacebook />
            </SocialIconLink> */}
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;