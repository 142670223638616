export const homeObjOne = {
    id: "what-is-n4",
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Num4 c’est quoi ?',
    description: [
      "Num4 est une plateforme en ligne conçue pour permettre aux artistes de proposer des \
      morceaux exclusifs à leurs super-fans. En offrant des contenus uniques, les artistes peuvent \
      générer une nouvelle source de revenus complémentaire aux plateformes de streaming et \
      au merchandising.", 
      
      "Cette initiative vise à renforcer la relation entre les artistes et leurs fans \
      les plus fidèles, qui auront la possibilité de soutenir directement leurs artistes préférés en \
      échange d'un accès privilégié à des morceaux exclusifs. En consolidant ainsi leur fanbase, les \
      artistes peuvent créer une expérience plus intime et exclusive pour leurs supporters."
    ].join("<br /><br />"),
    buttonLabel: false,//'Get Started',
    imgStart: '',
    img: require('../../images/n4-1.png'),
    alt: 'Num4 Prés',
    start: ''
  };
  
  export const homeObjTwo = {
    id: "plateform-n4",
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: "La plateforme",
    description: [
      "Num4 propose une interface épurée et intuitive pour \
      une expérience utilisateur sans complication. La \
      barre de menu dirige vers les tendances musicales, \
      tandis que la page \"home\" donne accès au compte, \
      aux playlists, et aux artistes populaires.", 
      
      "La personnalisation via des recommandations simplifie \
      la découverte d'artistes et la création de playlists. \
      Num4 se distingue par sa convivialité, facilitant la \
      gestion du compte et l'appréciation de la musique \
      préférée."
    ].join("<br /><br />"),
    buttonLabel: false,// 'Learn More',
    imgStart: 'true',
    img: require('../../images/n4-2.png'),
    alt: 'Num 4 explained',
    start: ''
  };
  
  export const homeObjThree = {
    id: "exclu-n4",
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: "L’exclusivité",
    description: [
      "Num4 va au-delà de l'ordinaire en offrant aux super-fans un catalogue \
      de morceaux exclusifs, chacun accompagné d'extraits. Cette \
      fonctionnalité unique permet aux passionnés de musique de \
      découvrir et d'acquérir des titres avant de les collectionner.", 

      "Grâce à Num4, les super-fans peuvent ainsi explorer, acheter et enrichir leur \
      collection musicale directement sur la plateforme, simplifiant ainsi \
      l'accès à une expérience musicale exclusive et personnalisée.",
    ].join("<br /><br />"),
    buttonLabel: false,// 'View Case Study',
    imgStart: '',
    img: require('../../images/n4-3.png'),
    alt: 'Num4 How to',
    start: ''
  };
  
  export const homeObjFour = {
    id: "artist-n4",
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: "La page artiste",
    description: [
      "La page artiste de Num4 simplifie la découverte musicale en présentant \
      les morceaux sous des catégories comme \"tendances\" et \"tops\". La barre \
      de menu intuitive facilite la navigation. La page offre également des \
      détails sur l'artiste, sa biographie, ses concerts à venir et ses \
      collaborations.", 
      
      "Les utilisateurs peuvent suivre leurs artistes préférés pour \
      être informés en avant-première des nouvelles sorties et des événements \
      à venir, créant ainsi une expérience musicale complète et informée."
    ].join("<br /><br />"),
    buttonLabel: false,// 'Sign Up Now',
    imgStart: 'start',
    img: require('../../images/n4-4.png'),
    alt: 'Vault',
    start: 'true'
  };