import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname, hash} = useLocation();

  useEffect(
    () => {
      window.scrollTo(0, 0);
    },
    [pathname]
  );

  useEffect(
    () => {
      const [, id] = hash?.split("#");
      
      console.log(hash, id)

      try {
        document.getElementById(id).scrollIntoView({behavior: "smooth"});
      } catch (e) {}
    },
    [hash]
  );

  return null;
}